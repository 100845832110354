import * as process from "process";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { getToken } from "@/api/cognito";

const API_URL = process.env.API_URL;

export enum TagTypes {
  TIMELINE = "HmrcTimeline",
  CHANNELS = "Channels",
  ONBOARDING = "Onboarding",
}

export enum VerifierEndpointNames {
  GET_TIMELINE = "getTimeline",
  UPDATE_TIMELINE_REVIEW_STATUS = "updateTimelineReviewStatus",
  REVIEW_FRAUD_INSIGHTS = "reviewFraudInsights",
  GET_CHANNELS = "getChannels",
  CREATE_CHANNEL = "createChannel",
  EDIT_CHANNEL = "editChannel",
  ABOUT_YOU = "aboutYou",
  GET_ONBOARDING = "getOnboarding",
  COMPLETE_PRODUCT_TOUR = "completeProductTour",
  POST_INBOUND_REFERENCING = "postInboundReferencing",
}

export enum NoAuthEndpointNames {
  SIGN_UP = "signUp",
  CONFIRM_EMAIL = "confirmEmail",
  INBOUND_REFERENCING_GET_EMPLOYER = "inboundReferencingGetEmployer",
}

const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: async (headers, { endpoint }) => {
      if (
        endpoint !== NoAuthEndpointNames.SIGN_UP &&
        endpoint !== NoAuthEndpointNames.CONFIRM_EMAIL &&
        endpoint !== NoAuthEndpointNames.INBOUND_REFERENCING_GET_EMPLOYER
      ) {
        const token = await getToken();
        headers.set("Access-Control-Allow-Origin", "*");
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);
          headers.set("Content-Type", "application/json");
        }
      }
      return headers;
    },
    responseHandler: async (response) => {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        return response.json();
      } else {
        return response.text();
      }
    },
  }),
  endpoints: () => ({}),
  tagTypes: [TagTypes.TIMELINE, TagTypes.CHANNELS, TagTypes.ONBOARDING],
});

export default baseApi;

import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "store";
import { setIdentifiedAction, setLoadingAction, setReadyAction } from "store/feature-flags/feature-flags-slice";

export enum Flag {
  VERIFIER_CREATE_REQUEST_OLD_FLOW = "verifierCreateRequestOldFlow",
  FROM_DEMO_TEMPLATE = "fromDemoTemplate",
  IMPORT_FROM_CSV = "enableImportFromCsv",
  ACTIVITY_VIEW = "enableActivityView",
}

interface LDUser {
  key: string;
  firstName: string;
  lastName: string;
  email: string;
  custom: {
    activeOrganisation?: string;
  };
}

export default function useFeatureFlags() {
  const ldClient = useLDClient();
  const flags = useFlags();
  const dispatch = useDispatch();

  const isLDClientAvailable = !!ldClient;

  const isLoading = useSelector<RootState, boolean>((state) => state.featureFlags.loading);
  const isReady = useSelector<RootState, boolean>((state) => state.featureFlags.isReady);
  const isIdentified = useSelector<RootState, boolean>((state) => state.featureFlags.isIdentified);

  const identify = useCallback(
    async (user: { id: string; firstName: string; lastName: string; email: string }, activeOrganisationId: string) => {
      if (!isReady) {
        return;
      }

      let ldUser: LDUser;
      if (user.email.startsWith("test") && user.email.endsWith("@mailinator.com")) {
        ldUser = {
          key: "e2e",
          firstName: "TestUserE2E",
          lastName: "TestUserE2E",
          email: user.email,
          custom: {
            activeOrganisation: activeOrganisationId,
          },
        };
      } else {
        ldUser = {
          key: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          custom: {},
        };
      }
      if (activeOrganisationId) {
        ldUser.custom.activeOrganisation = activeOrganisationId;
      }
      try {
        await ldClient?.identify(ldUser);
        dispatch(setIdentifiedAction());
      } catch (e) {
        console.error(e);
        dispatch(setIdentifiedAction());
      }
    },
    [isReady],
  );

  const init = useCallback(async () => {
    if (!isLDClientAvailable) {
      return;
    }
    dispatch(setLoadingAction());
    await ldClient?.waitUntilReady();
    dispatch(setReadyAction());
  }, [isLDClientAvailable]);

  const isFlagEnabled = (flag: Flag): boolean => {
    if (!isReady) {
      return false;
    }
    return flags[flag];
  };

  return {
    isLDClientAvailable,
    isReady,
    isLoading,
    isIdentified,
    identify,
    isFlagEnabled,
    init,
  };
}

import { AnyAction } from "redux";

import { FAILURE, REQUEST, SUCCESS } from "../../utils/actionType.util";
import { SIGN_OUT } from "../user/actionTypes";

import {
  GET_ORGANISATION,
  GET_ORGANISATION_USERS,
  SET_SETTINGS_MENU_OPEN,
  UPDATE_ORGANISATION_USER_PERMISSIONS,
} from "./actionTypes";
import { OrganisationState } from "./types";

const initialState: OrganisationState = {
  loading: false,
  error: null,
  organisationUsers: [],
  organisationAdmins: [],
  totalElements: 0,
  totalPages: 0,
  page: 1,
  limit: 10,
  organisationData: null,
  settingsMenuOpen: false,
};

const organisationReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_SETTINGS_MENU_OPEN:
      return {
        ...state,
        settingsMenuOpen: action.payload,
      };
    case REQUEST(GET_ORGANISATION_USERS):
    case REQUEST(GET_ORGANISATION):
    case REQUEST(UPDATE_ORGANISATION_USER_PERMISSIONS):
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS(GET_ORGANISATION):
      return {
        ...state,
        loading: false,
        organisationData: action.payload,
      };
    case SUCCESS(GET_ORGANISATION_USERS):
      return {
        ...state,
        loading: false,
        organisationUsers: action.payload.organisationUsers,
        organisationAdmins: action.payload.organisationAdmins,
        totalElements: action.payload.totalElements,
        page: action.payload.page,
        limit: action.payload.limit,
        totalPages: action.payload.totalPages,
      };
    case SUCCESS(UPDATE_ORGANISATION_USER_PERMISSIONS):
      return {
        ...state,
        loading: false,
      };
    case FAILURE(GET_ORGANISATION_USERS):
    case FAILURE(GET_ORGANISATION):
    case FAILURE(UPDATE_ORGANISATION_USER_PERMISSIONS):
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SUCCESS(SIGN_OUT):
      return { ...initialState };
    default:
      return state;
  }
};

export default organisationReducer;
